.molstar-container {
    width: 100%; /* Adjust to fit the parent */
    height: 100%; /* Adjust to fit the parent */
    position: relative; /* Keep it inside the parent */
    overflow: hidden; /* Prevent overflowing content */
    z-index: auto; /* Keep it on top of other elements */

}

.molstar-plugin {
    width: 100%;
    height: 100%;

}
